// Import our CSS
import "@/scss/app.scss";

import jQuery from 'jquery';
Object.assign(window, { $: jQuery, jQuery });

jQuery(function($) {

    $(document).on('click', '.menu-toggler', function () {
        var btn = $(this);
        let $menuWidth;
        btn.toggleClass('active');
        $('.nav-icon').toggleClass('open');
        $('#nav-drawer').toggleClass('open');
        if ($('#nav-drawer').hasClass('open')) {
            $menuWidth = $('#nav-drawer').outerWidth();
            $('#nav-drawer').css({
                '-webkit-transform': 'translateX(0)'
            }).attr('aria-expanded', true);
        } else {
            $menuWidth = 0;
            $('#nav-drawer').css({
                '-webkit-transform': 'translateX(100%)',
                'transform': 'translateX(100%)',
            }).attr('aria-expanded', false);
        }
        $('body').toggleClass('menu-active');
        $('body #wrapper').css({
            'left': -$menuWidth
        });

    });

    getHeaderHeight();


    $(document).on('click', '.acc-title', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.next('.acc-content').slideUp();
            $this.removeClass('active');
        } else {
            $('.acc-content').slideUp();
            $('.acc-title').removeClass('active');
            $this.addClass('active');
            $this.next('.acc-content').slideDown();
        }
    });

    $(document).on('click', '.tab-label', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.next('.tab-content').stop().slideUp();
            $this.removeClass('active');
        } else {
            $('.tab-content').slideUp();
            $('.tab-label').removeClass('active');
            $this.addClass('active');
            $this.next('.tab-content').stop().slideDown();
        }
    });

    $(document).on("click", '.has-submenu-side', function () {
        let $this = $(this);
        if ($this.hasClass('active')) {
            $this.find('ul').slideUp();
            $this.removeClass('active');
        } else {
            $('.has-submenu-side ul').slideUp();
            $('.has-submenu-side').removeClass('active');
            $this.addClass('active');
            $this.find('ul').slideDown();
        }
    });

    $( window ).on( "resize", function() {
        getHeaderHeight();
    });

    function getHeaderHeight() {
        let heightWindow = $(window).height();
        let width = $(window).width();
        let heightHeader = $('#header').outerHeight();

        $('#nav-drawer').css({
            'height': heightWindow ,
        });

        $('.cart-summary').css({
            'top': heightHeader
        });

        if ($('#hero-main').length) {
            if (width >= 1024) {
                $('#hero-main').css({
                    'max-height': "calc(100vh - " + heightHeader + "px)"
                })
            } else {
                $('#hero-main').css({
                    'max-height': "initial"
                })
            }
        }
    }

    $(document).on('click', '.banner-video-sound', function () {
        if($(this).hasClass("unmute"))
        {
            $(this).removeClass("unmute");
            $(this).find(".mute").removeClass("hidden");
            $(this).find(".unmute").addClass("hidden");
            $("#banner_video").prop("muted", 1);
        }
        else
        {
            $(this).addClass("unmute");
            $(this).find(".mute").addClass("hidden");
            $(this).find(".unmute").removeClass("hidden");
            $("#banner_video").prop("muted", 0);
        }
    });

    $(document).on('click', '.banner-video-play', function () {
        if($(this).hasClass("pause"))
        {
            $(this).removeClass("pause");
            $(this).find(".play").removeClass("hidden");
            $(this).find(".pause").addClass("hidden");
            document.getElementById('banner_video').play()
        }
        else
        {
            $(this).addClass("pause");
            $(this).find(".play").addClass("hidden");
            $(this).find(".pause").removeClass("hidden");
            document.getElementById('banner_video').pause()
        }
    });

    $(document).on('click', '.guide-btn', function (e) {
        e.preventDefault();
        if(! $(this).hasClass("active"))
        {
            $(".guide-btn").removeClass("active");
            $(this).addClass("active");

            runTvGuide();
        }
    });

    $(document).on('change', 'select.guide-time, select.guide-category', function (e) {
        e.preventDefault();
        runTvGuide();
    });

    if($("#guide-tabs").length) {
        runTvGuide();
    }

    $(document).on("click", '.discount-popup-close-button', function(e) {
        e.preventDefault();
        if($(".popupform-wrapper").length) {
            $(".popupform-wrapper").addClass('hidden');
            window.setCookie('popupFilled', 'closed', $(".popupform-wrapper").attr('data-close-timeout'));
        }
    });

    if(window.getCookie('popupFilled') == '' && $(".popupform-wrapper").length) {
        $(".popupform-wrapper").removeClass('hidden');
    }
});

function runTvGuide()
{
    let activeBtn = $(".guide-btn.active").length ? $(".guide-btn.active") : $(".guide-btn:first");
    //let guideTime = $(".guide-time").val();
    let guideCategory = $(".guide-category").val();

    $(".guide-no-results").hide();
    $(".guide-btn.active").removeClass("active");
    activeBtn.addClass("active");

    let append = "[data-day='" + activeBtn.data("day") + "']";
    //if(guideTime != '') {
    //    append += "[data-time='" + guideTime + "']";
    //}

    if(guideCategory != '') {
        append += "[data-category='" + guideCategory + "']";
    }

    $("#guide-tabs").find(".guide-item").hide();
    $("#guide-tabs").find(".guide-item" + append).show();

    if(! $("#guide-tabs").find(".guide-item:visible").length) {
        $(".guide-no-results").show();
    }
}

window.setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

window.getCookie = function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
